@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden; /* Hides scrollbar on body */
  scroll-behavior: smooth;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Hides scrollbar for WebKit browsers */
}
.scrollbar-hide {
  -ms-overflow-style: none; /* Hides scrollbar for IE and Edge */
  scrollbar-width: none; /* Hides scrollbar for Firefox */
}

.default-background {
  background-image: url("./img/background.jpg");
  background-size: cover; /* Stretches image to cover entire element */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  height: 100%; /* Sets element height to 100% of viewport */
  width: 100%; /* Sets element width to 100% of viewport */
}

.glassmorphism {
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(100px); /* Adds a blur effect for glassmorphism */
}

.montserrat-font {
  font-family: "Montserrat", sans-serif;
}

.primary-color {
  color: #67eafe;
}

#tsparticles {
  height: 100%;
}

.scroll-container {
  height: 100vh; /* Sets container height to viewport height */
  overflow-y: scroll; /* Enables vertical scrolling */
  scroll-snap-type: y mandatory; /* Activates vertical scroll snapping */
  scroll-behavior: smooth; /* Smooth scrolling */
}

/* Hides scrollbar in different browsers */
.scroll-container::-webkit-scrollbar {
  display: none; /* Hides scrollbar in WebKit browsers (Chrome, Safari) */
}

.scroll-container {
  -ms-overflow-style: none; /* Hides scrollbar in IE and Edge */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}

.page-block {
  scroll-snap-align: start; /* Aligns block to the start of container */
  scroll-snap-stop: always; /* Ensures block stops at snap point */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Allows absolute positioning within block */
}

/* Adjusts the first block to ensure Particle effect covers the background */
.page-block:first-child {
  position: relative;
  overflow: hidden; /* Ensures Particle effect stays within block limits */
}

@media (max-width: 638px) {
  .scroll-container {
    scroll-snap-type: none; /* Disables scroll snapping on mobile devices */
    overflow-y: auto; /* Enables normal scrolling */
    scroll-behavior: auto;
  }

  .block {
    scroll-snap-align: none; /* Disables snapping alignment */
  }
}
